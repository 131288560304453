import moment from "moment";
import numeral from 'numeral';

const Utils = {
    state($v) {
        return function (field) {
            return $v.form[field].$dirty ? !$v.form[field].$invalid : null;
        }
    },
    formatDate(date, format) {
        return moment(date).format(format);
    },
    formatNumber(number, format) {
        return numeral(number).format(format);
    },
    formatCurrency(id) {
        return id === 1 ? 'MXN' : 'USD';
    },
    formatQuoteStatus(status) {
      switch(status) {
          case 1:
              return 'STATUS_SENT_LABEL';
          case 2:
              return 'STATUS_QUOTED_LABEL';
          case 3:
              return 'STATUS_ACCEPTED_LABEL';
          case 4:
              return 'STATUS_PROCESSED_LABEL';
          case 5:
              return 'STATUS_REJECTED_LABEL';
          default:
              return 'STATUS_UNKNOWN_LABEL';
      }
    }
};

export default Utils;
