<template>
  <b-container fluid>
    <b-row>
      <b-col class="mt-auto" offset="3" md="6">
        <b-card class="mt-5" bg-variant="dark" body-text-variant="light" :title="$t('RECOVERY_PAGE_TITLE') | capitalize">
          <b-card-text>
            <b-form @submit.prevent="recover">
              <b-form-group id="username" :label="$t('USER_EMAIL_LABEL') | capitalize">
                <b-form-input v-model.trim="$v.form.email.$model" :state="state('email')"></b-form-input>
              </b-form-group>

              <b-button class="d-block w-100" :disabled="$v.form.$invalid || sending" variant="primary" type="submit">
                {{ $t('RECOVER_BUTTON_LABEL') | capitalize}}
              </b-button>
            </b-form>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {email, minLength, required} from "vuelidate/lib/validators";
import Users from "@/users";
import Constants from "@/constants";
import Utils from "@/utils";
import {SendsMessages} from "@/mixins";

export default {
  name: "Recovery",
  mixins: [SendsMessages],
  validations: {
    form: {
      email: {required, email, minLength: minLength(4)}
    }
  },
  data() {
    return {
      form: {
        email: undefined
      },
      sending: false
    }
  },
  methods: {
    recover: async function () {
      try {
        this.sending = true;
        await Users.sendRecoveryEmail(this.form.email);
        this.sendMessage('RECOVERY_MAIL_SENT_SUCCESS_MESSAGE', this.form);
        await this.$router.push({path: '/login'});
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('RECOVERY_MAIL_SENT_ERROR_MESSAGE', {}, e);
      } finally {
        this.sending = false;
      }
    }
  },
  computed: {
    state() {
      return Utils.state(this.$v);
    }
  }
}
</script>

<style scoped>

</style>
